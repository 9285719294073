const Blogs = () => {
  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <h1 style={{ color: "white" }}>Blogs </h1>
    </div>
  );
};

export default Blogs;
